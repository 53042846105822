import axios from "axios";
import { METHOD } from "../constants";
import { getData } from "../helpers/localStorage";

const baseURL = process.env.REACT_APP_BASE_URL;
const cancelToken = axios.CancelToken;
const source = cancelToken.source();

const baseRequest = async (method, url, sendData = {}) => {
  const headers = await getHeader();

  try {
    const { status, data } = await axios({
      baseURL,
      method,
      url,
      data: sendData,
      headers,
      cancelToken: source.token,
    });

    return { status, data };
  } catch ({ response: { status, data } }) {
    if(status === 401) {
      if(data?.name === 'TokenExpiredError') {
        window.alert('Token expired, please login again')
      }
    }
    return { status, data };
  }
};

export const getHeader = async (optional) => {
  const token = getData("authUser");
  const headers = {
    "Content-Type": "application/json",
  }
  if (optional && typeof optional === 'object') {
    for (const key in Object.keys(optional)) {
      headers[key] = optional[key];
    }
  }
  if (token) headers['Authorization'] = `Bearer ${token}`;

  return headers;
}

const get = (url) => baseRequest(METHOD.GET, url);
const post = (url, data) => baseRequest(METHOD.POST, url, data);
const put = (url, data) => baseRequest(METHOD.PUT, url, data);
const patch = (url, data) => baseRequest(METHOD.PATCH, url, data);
const del = (url, data) => baseRequest(METHOD.DELETE, url, data);

export { get, post, put, del, patch };
